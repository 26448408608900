import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Zone(props) {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "zonehome",
  });
  const initIcon = [
    {
      img: require("./img/zone/fur_ico.jpg"),
      title: t("fur.title"),
      desc: t("fur.desc"),
      active: false,
    },
    {
      img: require("./img/zone/elec_ico.jpg"),
      title: t("elec.title"),
      desc: t("elec.desc"),
      active: false,
    },
    {
      img: require("./img/zone/garden_ico.jpg"),
      title: t("garden.title"),
      desc: t("garden.desc"),
      active: false,
    },
    /*
    {
      img: require("./img/zone/design_ico.jpg"),
      title: t("design.title"),
      desc: t("design.desc"),
      active: false,
    },
    {
      img: require("./img/zone/health_ico.jpg"),
      title: t("health.title"),
      desc: t("health.desc"),
      active: false,
    },
    {
      img: require("./img/zone/outdoor_ico.jpg"),
      title: t("outdoor.title"),
      desc: t("outdoor.desc"),
      active: false,
    },
    {
      img: require("./img/zone/pet_ico.jpg"),
      title: t("pet.title"),
      desc: t("pet.desc"),
      active: false,
    },
    {
      img: require("./img/zone/travel_ico.jpg"),
      title: t("travel.title"),
      desc: t("travel.desc"),
      active: false,
    },
    {
      img: require("./img/zone/inter_ico.jpg"),
      title: t("inter.title"),
      desc: t("inter.desc"),
      active: false,
    },*/
    {
      img: require("./img/zone/food_ico.jpg"),
      title: t("food.title"),
      desc: t("food.desc"),
      active: false,
    },
  ];

  const [icons, setIcons] = useState(initIcon);

  const iconClick = (index) => {
    let newIcons = icons.map((icon, i) => {
      if (i === index) {
        icon.active = !icon.active;
      } else {
        icon.active = false;
      }
      return icon;
    });
    setIcons(newIcons);

    let isShow = newIcons.some((icon) => icon.active);
    setIsShow(isShow);
  };

  useEffect(() => {
    setIcons(initIcon);
  }, [i18n.language]);

  const [isShow, setIsShow] = useState(false);

  return (
    <section
      id="zone"
      className={`mt-10 md:mt-16 ${!isShow ? "mb-10 md:mb-16" : ""}`}
    >
      <div className="w-full text-center text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl">
        {t("title")}
      </div>
      <div className="border-b mt-1 w-[320px] border-[#7E7E7E] mx-auto mb-7"></div>
      <div className="flex flex-wrap gap-4 md:gap-16 my-7 xl:max-w-5xl mx-auto justify-center">
        {icons.map((icon, index) => (
          <div
            key={index + "-ico"}
            className={`w-[140px] h-[178px] rounded-lg overflow-hidden cursor-pointer ${
              icon.active ? "opacity-100" : "opacity-50"
            } hover:opacity-100 transition-opacity duration-300`}
            onClick={() => iconClick(index)}
          >
            <img
              src={icon.img}
              alt="zone_ico"
              className={`object-cover h-full`}
            />
          </div>
        ))}
      </div>
      {icons.map(
        (icon, index) =>
          icon.active && (
            <div className="bg-[#EFEFEF] w-full py-8">
              <div className="w-fit flex flex-col items-center gap-4 md:grid grid-cols-2 md:w-3/4 xl:w-1/2 mx-auto">
                <div className="w-[200px] h-[256px] rounded-lg overflow-hidden">
                  <img
                    src={icon.img}
                    alt="badge-ico"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="h-full flex flex-col justify-center">
                  <div className="text-3xl text-[#7E7E7E] font-semibold">
                    {icon.title}
                  </div>
                  <ul
                    className="list-disc list-inside text-[#7E7E7E]"
                    dangerouslySetInnerHTML={{ __html: icon.desc }}
                  ></ul>
                </div>
              </div>
            </div>
          )
      )}
    </section>
  );
}
