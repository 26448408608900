import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NavMenu(props) {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "navmenu" });

  const nav = useNavigate();

  const navItems = [
    //{ title: t("home"), href: "/" },
    { title: t("about"), href: "/" },
    { title: t("exhibitor"), href: "/exhibitor" },
    { title: t("visitor"), href: "/visitor" },
    //{ title: t("activities"), href: "#" },
    //{ title: t("news"), href: "#" },
    { title: t("contact"), href: "/contactus" },
  ];

  const [isShow, setIsShow] = useState(false);

  return (
    <header className="fixed w-full 2xl:container z-[99999]">
      <nav className="hidden w-full lg:flex items-center justify-around h-[72px] overflow-hidden bg-gradient-to-r from-[#2C348B] to-[#E95B28] text-white shadow-md">
        <div>
          <a href="/">
            <img src={require("../img/Logo-wf-white.png")} />
          </a>
        </div>
        <div>
          <ul className="flex items-center justify-between py-2">
            {navItems.map((i, x) => (
              <li key={x} className={`border-r last:border-r-0 hover:px-0`}>
                <div className="hover:bg-white/20 hover:rounded-full w-full px-[22px] py-0.5">
                  <a href={i.href}>{i.title}</a>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul className="flex items-center justify-between gap-1">
            <li
              className={`w-[32px] h-[32px] rounded-full flex items-center justify-center ${
                i18n.language === "en"
                  ? "border bg-white text-[#E95B28]"
                  : "hover:font-bold hover:text-lg"
              }`}
            >
              <div
                onClick={() => {
                  i18n.changeLanguage("en");
                  localStorage.setItem("i18nextLng", "en");
                }}
                className={`cursor-pointer`}
              >
                EN
              </div>
            </li>
            <div className="border-r h-[30px]"></div>
            <li
              className={`w-[32px] h-[32px] rounded-full flex items-center justify-center ${
                i18n.language === "th"
                  ? "border bg-white text-[#E95B28]"
                  : "hover:font-bold hover:text-lg"
              }`}
            >
              <div
                onClick={() => {
                  i18n.changeLanguage("th");
                  localStorage.setItem("i18nextLng", "th");
                }}
                className={`cursor-pointer`}
              >
                TH
              </div>
            </li>
          </ul>
        </div>
        <div>
          <div
            className="border rounded-full px-[15px] py-[8px] hover:bg-white hover:bg-opacity-25"
            onClick={() => nav("/contactus/reserve")}
          >
            {t("reserve")}
          </div>
        </div>
      </nav>
      <nav className="w-full lg:hidden">
        <div className="w-full bg-white shadow-md h-[72px] px-5 flex items-center justify-between relative z-10">
          <div
            className="flex flex-col w-[32px] h-[32px] justify-around cursor-pointer"
            onClick={() => setIsShow(!isShow)}
          >
            <div
              className={`border-b-4 rounded-full border-[#2C348B] transition-all duration-300 ${
                isShow ? "rotate-45 translate-y-[11px]" : ""
              }`}
            ></div>
            <div
              className={`border-b-4 rounded-full border-[#2C348B] transition-all duration-300 ${
                isShow ? "border-transparent" : ""
              }`}
            ></div>
            <div
              className={`border-b-4 rounded-full border-[#2C348B] transition-all duration-300 ${
                isShow ? "-rotate-45 -translate-y-[11px]" : ""
              }`}
            ></div>
          </div>
          <div>
            <a href="/">
              <img src={require("../img/Logo-wf.png")} />
            </a>
          </div>
        </div>
        <div
          className={`bg-white shadow-md w-3/4 md:w-[400px] transition duration-300 ${
            isShow ? "" : "-translate-y-[2000px]"
          } absolute top-[72px] left-0 z-0`}
        >
          <div className="px-5 mb-5">
            <ul className="flex flex-col">
              {navItems.map((i, x) => (
                <li
                  key={x}
                  className={`px-[22px] border-b pt-8 rounded-tr-xl first:mt-8 hover:bg-[#2C348B] hover:text-white`}
                >
                  <a href={i.href}>{i.title}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="px-5 pb-5">
            <ul className="flex items-center gap-3">
              <li
                className={`w-[32px] h-[32px] rounded-full flex items-center justify-center ${
                  i18n.language === "en"
                    ? "border bg-white text-[#E95B28]"
                    : "hover:font-bold hover:text-lg"
                }`}
              >
                <div
                  onClick={() => {
                    i18n.changeLanguage("en");
                    localStorage.setItem("i18nextLng", "en");
                  }}
                  className={`cursor-pointer`}
                >
                  EN
                </div>
              </li>
              <div className="border-r h-[30px]"></div>
              <li
                className={`w-[32px] h-[32px] rounded-full flex items-center justify-center ${
                  i18n.language === "th"
                    ? "border bg-white text-[#E95B28]"
                    : "hover:font-bold hover:text-lg"
                }`}
              >
                <div
                  onClick={() => {
                    i18n.changeLanguage("th");
                    localStorage.setItem("i18nextLng", "th");
                  }}
                  className={`cursor-pointer`}
                >
                  TH
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
