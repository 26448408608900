import React, { Component } from "react";
import NavMenu from "./NavMenu";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

export default function Layout(props) {
  return (
    <div className="2xl:container mx-auto">
      <NavMenu />
      <div className="pt-[70px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
