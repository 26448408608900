import React from "react";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "exhibitor.profile",
  });
  return (
    <section id="profile" className="my-11">
      <div className="grid grid-cols-1 md:grid-cols-[1.5fr_1fr] px-10 place-items-center">
        <div>
          <h2 className="text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl">
            {t("title")}
          </h2>
          <div className="mt-8">
            <p className="text-gray-500">
              <strong>{t("wf")} </strong>
              {t("desc1")}
            </p>
          </div>
          <div className="mt-8">
            <p className="text-gray-500">
              <strong>{t("wf")} </strong>
              {t("desc2")}
            </p>
          </div>
          <div className="mt-8">
            <p className="text-gray-500">
              <strong>{t("wf")} </strong>
              {t("desc3")}
            </p>
          </div>
          <div className="mt-8">
            <p className="text-[#cd3f23] text-center text-xl font-semibold">
              "{t("motto")}"
            </p>
          </div>
        </div>
        <div className="w-3/4 md:w-full">
          <img
            src={require("./img/profilepic.png")}
            alt="Profile"
            className="object-cover"
          />
        </div>
      </div>
    </section>
  );
}
