import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import CustomerCard from "./customerCard";

export default function Testimonial() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "testhome",
  });

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_fp;

  const [rlist, setRlist] = useState([]);

  const getReview = async () => {
    try {
      const res = await Axios.get(url + "/getReview/" + i18n.language);
      setRlist(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getReview();
  }, [i18n.language]);

  return (
    <section id="testimonial" className="mt-16 relative overflow-hidden">
      <div className="absolute top-0 right-0 opacity-20 -z-10">
        <img
          src={require("./img/hero-circle2.png")}
          alt="circle"
          className=""
        />
      </div>
      <div className="py-12">
        <div className="w-full text-center text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl">
          {t("title")}
        </div>
        <div className="border-b mt-1 w-[320px] border-[#7E7E7E] mx-auto mb-7"></div>
      </div>
      <div className="mb-24 w-4/5 mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 place-items-center">
        {rlist.map((r, i) => (
          <CustomerCard review={r} key={`list-${i}`} />
        ))}
      </div>
    </section>
  );
}
