import React from "react";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "visitor",
  });
  return (
    <section id="ex-hero" className="relative">
      <div className="w-full h-[240px] sm:h-[400px] lg:h-[650px] overflow-hidden">
        <img
          src={require("./img/bg-hero.jpg")}
          alt="hero"
          className="h-full object-cover object-center"
        />
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-2xl sm:text-3xl md:text-4xl lg:text-6xl text-center sm:text-nowrap font-semibold shadow-lg">
        {t("title")}
      </div>
    </section>
  );
}
