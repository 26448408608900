import React from "react";
import { useTranslation } from "react-i18next";

export default function Place() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "placehome",
  });

  return (
    <section id="place-section" className="py-16 bg-[#2C348B]">
      <div className="w-full text-center text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
        {t("title")}
      </div>
      <div className="border-b mt-1 w-[320px] border-white mx-auto mb-7"></div>
      <div className="max-w-4xl mx-auto px-6">
        <p className="text-white text-center">{t("desc")}</p>
      </div>
      <div className="w-full px-6 max-w-5xl mx-auto mt-2 md:mt-6">
        <img
          src={require("./img/map.png")}
          alt="place"
          className="w-full object-fill"
        />
      </div>
    </section>
  );
}
