import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import Servicecard from "./servicecard";

export default function Services() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "exhibitor.services",
  });

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_ext;

  const [slist, setSlist] = useState([]);

  const getServices = async () => {
    try {
      const res = await Axios.get(url + "/getServices/" + i18n.language);
      setSlist(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getServices();
  }, [i18n.language]);

  const [cat, setCat] = useState([]);

  //find unique value
  const getUnique = () => {
    let ic = [];
    slist.map((d, i) => {
      if (d.category != undefined) {
        ic.push({
          cat: d.category,
          catid: d.catid.replace(/\s+/g, "").toLowerCase(),
        });
      }
    });
    const uniqueCat = [...new Set(ic.map((item) => JSON.stringify(item)))].map(
      (item) => JSON.parse(item)
    );
    setCat(uniqueCat);
  };

  useEffect(() => {
    getUnique();
  }, [slist]);

  return (
    <section id="services" className="my-36 px-8 md:px-12 lg:px-20">
      <div className="pb-12">
        <h2 className="w-full text-center text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl">
          {t("title")}
        </h2>
        <div className="border-b mt-1 w-[320px] border-gray-500 mx-auto mb-7"></div>
      </div>
      <div>
        {cat.map((c, i) => (
          <div className="mb-14">
            <h3
              className="text-center text-3xl text-gray-500 mb-8 md:mb-12"
              id={c.catid}
            >
              {c.cat}
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-4 place-items-center">
              {slist.map(
                (s, i) =>
                  s.category === c.cat && <Servicecard key={i} data={s} />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
