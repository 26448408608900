import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./layout/Layout";
import "./custom.css";
import Prelaunch from "./Home/prelaunch";

import i18n from "./i18n";

export default function App(props) {
  const prelaunch = false;
  return (
    <Routes>
      {prelaunch && <Route path="/" element={<Prelaunch />} />}
      <Route element={<Layout />}>
        {AppRoutes.map((route, i) => (
          <Route key={i} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
}
