import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Axios from "axios";
import style from "./contactus.module.css";

export default function Contactus(props) {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "contactus",
  });

  const { res } = useParams();

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_ctc;

  const initData = {
    name: "",
    email: "",
    tel: "",
    subject: "0",
    message: "",
  };
  const [data, setData] = useState(initData);

  useEffect(() => {
    if (res !== undefined) {
      setData({ ...data, subject: "2" });
    }
  }, [res]);

  const sendMessage = async () => {
    try {
      await Axios.post(url + "/postContact", data);
      setData(initData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.title = "Home Mega Show - Contact Us";
  }, []);

  return (
    <section id="contactus">
      <div className="px-5 py-16 sm:px-20 sm:py-36 lg:px-36 lg:py-36 bg-gradient-to-tr from-[#0C0E25] via-[#2C348B] to-[#0C0E25] relative z-0">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img
            src={require("./img/hero-circle.png")}
            alt="Hero"
            className={`w-full md:w-fit mx-auto`}
          />
        </div>
        <div className="w-full h-full bg-white shadow-lg px-4 sm:px-11 py-9 lg:px-24 relative z-20">
          <h2 className="text-2xl text-[#2c348b] font-semibold w-full text-center mb-10">
            {t("title")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className={`${style.form_group}`}>
              <input
                type="text"
                placeholder={t("name")}
                className={`${style.form_field}`}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name}
              />
              <label htmlFor="name" className={`${style.form_label}`}>
                {t("name")}
              </label>
            </div>
            <div className={`${style.form_group}`}>
              <input
                type="email"
                placeholder={t("email")}
                className={`${style.form_field}`}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                value={data.email}
              />
              <label htmlFor="email" className={`${style.form_label}`}>
                {t("email")}
              </label>
            </div>
            <div className={`${style.form_group}`}>
              <input
                type="text"
                placeholder={t("tel")}
                className={`${style.form_field}`}
                onChange={(e) => setData({ ...data, tel: e.target.value })}
                value={data.tel}
              />
              <label htmlFor="tel" className={`${style.form_label}`}>
                {t("tel")}
              </label>
            </div>
          </div>
          <div className={`${style.form_group} mt-8`}>
            <select
              id="subject"
              className={`${
                data.subject !== "0"
                  ? `${style.form_field} border-[#efefef] bg-white`
                  : `${style.beforeselect} border-white bg-[#efefef]`
              } border-[3px] max-md:w-full w-1/2 h-[40px]`}
              onChange={(e) => setData({ ...data, subject: e.target.value })}
              value={data.subject}
            >
              <option value="0" disabled selected hidden></option>
              {[t("sub1"), t("sub2"), t("sub3")].map((s, id) => (
                <option value={id + 1} key={id}>
                  {s}
                </option>
              ))}
            </select>
            <label
              htmlFor="subject"
              className={`${
                data.subject != "0" ? style.selected_label : style.form_label
              }`}
            >
              {t("subject")}
            </label>
          </div>
          <div className={`${style.form_group} mt-6`}>
            <textarea
              id="message"
              placeholder={t("message")}
              rows={3}
              className={`${style.form_field} h-[120px]`}
              onChange={(e) => setData({ ...data, message: e.target.value })}
              value={data.message}
            />
            <label htmlFor="message" className={`${style.form_label}`}>
              {t("message")}
            </label>
          </div>
          <div className="flex w-full justify-center">
            <button
              className="w-4/5 md:w-3/5 lg:w-2/5 border-[#2c348b] border text-[#2c348b] py-2 rounded-full mt-6 text-xl hover:bg-[#2c348b] hover:text-white"
              onClick={() => sendMessage()}
            >
              {t("send")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
