import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Servicecard(props) {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "exhibitor.services",
  });
  const [data, setData] = useState({});

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div className="bg-gray-100 rounded-md max-w-[308px] w-full h-[227px] py-4 px-5 flex flex-col justify-between">
      <div className="text-[#2C348B] text-lg">{data.name}</div>
      <div className="text-gray-500 overflow-y-auto">
        <div className="pb-1">{data.contact}</div>
        <div className="text-sm font-medium">{data.company}</div>
        <div className="text-sm">{data.address}</div>
        <div className="text-sm">
          {t("mail")}: {data.mail}
        </div>
        <div className="text-sm">
          {t("tel")}: {data.tel}
        </div>
      </div>
    </div>
  );
}
