import React from "react";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "abouthome" });
  return (
    <section id="about-home" className="relative py-16 md:py-24">
      <div
        id="bg-blue"
        className="hidden md:block absolute top-0 left-0 w-full opacity-10 z-0"
      >
        <div className="mx-auto grid gap-3 grid-cols-[1.5fr_1fr_1.5fr_1fr] mb-3">
          <div className="aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_1.jpg")}
              alt="about_pix_1"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_2.jpg")}
              alt="about_pix_2"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_3.jpg")}
              alt="about_pix_3"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_4.jpg")}
              alt="about_pix_4"
              className="object-cover"
            />
          </div>
        </div>
        <div className="mx-auto grid gap-3 grid-cols-[1fr_1.5fr_1fr_1.5fr]">
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_5.jpg")}
              alt="about_pix_5"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_6.jpg")}
              alt="about_pix_6"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_7.jpg")}
              alt="about_pix_7"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_8.jpg")}
              alt="about_pix_8"
              className="object-cover"
            />
          </div>
        </div>
      </div>
      <div className="text-center mb-8 relative z-10">
        <div className="text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl py-8">
          Home Mega Show
        </div>
        <div className="text-[#7E7E7E] w-[90%] sm:w-3/4 md:w-1/2 mx-auto text-center">
          {t("desc")}
        </div>
      </div>
      <div className="relative z-10">
        <div className="w-[90%] md:w-3/4 mx-auto grid gap-3 grid-cols-2 sm:grid-cols-4 md:grid-cols-[1.5fr_1fr_1.5fr_1fr] mb-3">
          <div className="aspect-w-2 md:aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_1.jpg")}
              alt="about_pix_1"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_2.jpg")}
              alt="about_pix_2"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-2 md:aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_3.jpg")}
              alt="about_pix_3"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_4.jpg")}
              alt="about_pix_4"
              className="object-cover"
            />
          </div>
        </div>
        <div className="w-[90%] md:w-3/4 mx-auto grid gap-3 grid-cols-2 sm:grid-cols-4 md:grid-cols-[1fr_1.5fr_1fr_1.5fr]">
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_5.jpg")}
              alt="about_pix_5"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-2 md:aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_6.jpg")}
              alt="about_pix_6"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={require("./img/about/Asset_7.jpg")}
              alt="about_pix_7"
              className="object-cover"
            />
          </div>
          <div className="aspect-w-2 md:aspect-w-3 aspect-h-2">
            <img
              src={require("./img/about/Asset_8.jpg")}
              alt="about_pix_8"
              className="object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
