import React, { useState, useEffect } from "react";

export default function CustomerCard(props) {
  const [review, setReview] = useState({});
  useEffect(() => {
    setReview(props.review);
  }, [props.review]);

  return (
    <div
      id="customer-card"
      className="w-full max-w-[300px] h-56 overflow-hidden rounded-lg bg-[#efefef] px-4 py-5 flex flex-col justify-between hover:bg-[#2C348B] group"
    >
      <div
        id="quote"
        className="w-full text-gray-500 group-hover:text-white overflow-y-auto"
      >
        {review.reviewText}
      </div>
      <div className="flex gap-4">
        <div className="border w-12 h-12 rounded-full overflow-hidden flex-none">
          <img
            src={
              "https://worldfair.blob.core.windows.net/tms-review/" +
              review.picFile
            }
            alt="customer"
            className="w-full h-full object-cover"
          />
        </div>
        <div>
          <div className="text-[#2C348B] font-semibold text-lg group-hover:text-white text-clip overflow-hidden">
            {review.name}
          </div>
          <div className="text-[#7E7E7E] group-hover:text-white text-nowrap">
            {review.company}
          </div>
        </div>
      </div>
    </div>
  );
}
