import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SlLocationPin } from "react-icons/sl";
import { IoTimeOutline } from "react-icons/io5";

import style from "./style.module.css";

export default function Hero(props) {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "hero" });

  const registOn = true;

  const heroSectionRef = useRef(null);
  const [heroHeight, setHeroHeight] = useState(0);
  const [fixHero, setFixHero] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const heroImage = document.querySelector(".hero-image");
      const bgImage = document.querySelector(".bg-hero");
      const scrollPosition = window.scrollY;
      const scaleFactor = 1 + scrollPosition / 1000; // Adjust the divisor to control the scaling speed
      const rotateAngle = scrollPosition / 10; // Adjust the divisor to control the rotation speed
      heroImage.style.transform = `scale(${scaleFactor}) rotate(${rotateAngle}deg)`;
      const maxScroll = 800; // Adjust this value to control when the image fully fades out
      const opacity = Math.max(1 - scrollPosition / maxScroll, 0);
      bgImage.style.opacity = opacity;
    };

    window.addEventListener("scroll", handleScroll);

    setHeroHeight(heroSectionRef.current.clientHeight);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > heroHeight) {
        setFixHero(false);
      } else {
        setFixHero(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const registClick = () => {
    let campaign = localStorage.getItem("campaign");
    if (campaign !== null) {
      window.location.href =
        "https://www.worldfair.co.th/i125/preregistration/" + campaign;
    } else {
      window.location.href = "https://www.worldfair.co.th/i125/preregistration";
    }
  };

  return (
    <section
      id="hero-section"
      className={`max-h-screen overflow-hidden w-full 2xl:container`}
      ref={heroSectionRef}
      style={{
        position: fixHero ? "fixed" : "relative",
        zIndex: fixHero ? 100 : 0,
      }}
    >
      <div className="h-screen flex items-center bg-gradient-to-tr from-[#0C0E25] via-[#2C348B] to-[#0C0E25] relative">
        <div className="absolute z-0 top-0 left-0 w-full h-full">
          <img
            src={require("./img/bg-hero.png")}
            alt="bg-hero"
            className={`w-full h-full object-cover opacity-50 bg-hero ${style.parallax}`}
          />
        </div>
        <img
          src={require("./img/hero-circle.png")}
          alt="Hero"
          className={`w-full md:w-fit mx-auto hero-image ${style.parallax}`}
        />
      </div>

      <div className="absolute top-1/2 -translate-y-1/2 w-full">
        <div className="w-full mb-6 sm:mb-5 md:mb-3">
          <div className="max-w-fit mx-auto">
            <img
              src={require("./img/logo-white.png")}
              alt="Hero"
              className="w-[90%] md:w-full md:max-w-full mx-auto"
            />
          </div>
        </div>
        <p className="text-xl sm:text-3xl text-white mx-auto max-w-xs sm:max-w-md text-center md:max-w-fit mb-6 sm:mb-5 md:mb-3">
          {t("title")}
        </p>
        <div className="w-full bg-gradient-to-r from-black/0 via-black/100 to-black/0 flex flex-col sm:flex-row items-center justify-center sm:gap-4 mb-14 sm:mb-8 md:mb-4 py-4">
          <div className="text-white text-6xl sm:text-[86px] md:text-[96px] font-bold">
            11-19
          </div>
          <div className="hidden sm:block border-l sm:h-[90px] md:h-[100px]"></div>
          <div className="hidden sm:block sm:w-5 md:w-6 sm:h-[90px] md:h-[100px] rounded-full bg-gradient-to-b from-[#E95B28] to-[#F7941D]"></div>
          <div>
            <p className="text-white font-semibold text-3xl md:text-4xl text-center sm:text-left">
              {t("month")}
            </p>
            <div className="sm:hidden block mx-auto rounded-full w-[120px] h-3 bg-gradient-to-r from-[#E95B28] to-[#F7941D] my-3"></div>
            <p className="text-white font-semibold text-lg flex items-center gap-2">
              <IoTimeOutline /> {t("time")}
            </p>
            <p className="text-white font-semibold text-lg flex items-center gap-2">
              <SlLocationPin /> {t("loc")}
            </p>
          </div>
        </div>
        {registOn && (
          <div className="w-full">
            <div
              className="border rounded-full w-[90%] sm:w-[220px] py-2 text-white text-center mx-auto hover:bg-white hover:text-[#2C348B]"
              onClick={registClick}
            >
              {t("regist")}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
