import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Hotelcard(props) {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "visitor.venue",
  });

  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div
      id="hotelcard"
      className="w-full min-[450px]:w-[415px] max-[315px]:h-[715px] h-[620px] sm:h-[715px] p-4 shadow-lg flex flex-col gap-7"
    >
      <div className="w-full h-[200px]">
        <img
          src={data.pic ? require("./img/hotels/" + data.pic) : ""}
          alt="hotel-pic"
          className="object-cover"
        />
      </div>
      <div className="flex flex-col justify-between h-full">
        <div>
          <h4 className="text-2xl font-medium text-[#2C348B] mb-4">
            {data.name}
          </h4>
          <p className="text-gray-500">{data.detail}</p>
        </div>
        <div>
          <p className="text-gray-500">{data.address}</p>
          <p className="text-gray-500 font-medium mb-2">
            {t("web")}: {data.web}
          </p>
          <p className="text-gray-500 font-medium">
            {t("tel")} {data.tel}
          </p>
          <p className="text-gray-500 font-medium">
            {t("mail")}: {data.email}
          </p>
        </div>
      </div>
    </div>
  );
}
