import React from "react";
import { useTranslation } from "react-i18next";

export default function Support(props) {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "supporthome",
  });

  return (
    <section id="support" className="my-10 md:my-16">
      <div className="py-12 flex flex-col items-center">
        <div className="text-[#2C348B] text-center font-semibold text-2xl sm:text-3xl md:text-4xl">
          {t("title")}
        </div>
        <div className="border-b mt-1 w-[320px] border-[#7E7E7E] mb-7"></div>
      </div>
      <div className="flex flex-wrap w-4/5 md:w-3/4 gap-4 justify-center mx-auto">
        {Array.from({ length: 44 }).map((_, i) => (
          <img
            key={i}
            src={require("./img/logo/Asset " + (i + 2) + ".png")}
            alt="support"
            className="w-20 h-20 object-contain"
          />
        ))}
      </div>
    </section>
  );
}
