import Home from "./Home/Home";
import Exhibitor from "./exhibitor/exhibitor";
import Visitor from "./visitor/visitor";
import Contactus from "./contactus/contactus";

const AppRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/:cp",
    element: <Home />,
  },
  {
    path: "/exhibitor",
    element: <Exhibitor />,
  },
  {
    path: "/visitor",
    element: <Visitor />,
  },
  {
    path: "/contactus",
    element: <Contactus />,
  },
  {
    path: "/contactus/:res",
    element: <Contactus />,
  },
];

export default AppRoutes;
