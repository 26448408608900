import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer(props) {
  const { t } = useTranslation("translation");

  const navItems = [
    //{ title: t("navmenu.home"), href: "/" },
    { title: t("navmenu.about"), href: "/" },
    { title: t("navmenu.exhibitor"), href: "/exhibitor" },
    { title: t("navmenu.visitor"), href: "/visitor" },
    //{ title: t("navmenu.activities"), href: "#" },
    //{ title: t("navmenu.news"), href: "#" },
    { title: t("navmenu.contact"), href: "/contactus" },
  ];

  const socialItems = [
    { title: "@worldfairexpo", img: "ig.png", imgw: "ig-w.png" },
    { title: "worldfairexhibition", img: "tt.png", imgw: "tt-w.png" },
    { title: "Worldfair Expo", img: "yt.png", imgw: "yt-w.png" },
    { title: "@worldfairexpo", img: "ln.png", imgw: "ln-w.png" },
    { title: "@worldfairexpo", img: "fb.png", imgw: "fb-w.png" },
  ];
  return (
    <section id="footer">
      <div className="w-full bg-[#2C348B] min-h-[200px] h-fit grid grid-cols-1 sm:grid-cols-[2fr_1fr] md:grid-cols-2 relative">
        <div className="py-4 absolute top-0 right-0 hidden md:block">
          <img
            src={require("../img/footer-bg.png")}
            alt="logo"
            className="h-[180px] object-cover opacity-25"
          />
        </div>
        <div className="sm:flex gap-4 px-[40px] md:px-[80px] py-4 sm:py-[24px]">
          <div className="w-[80px] sm:w-[60px] sm:min-w-[60px] mx-auto mb-4 sm:mx-0 sm:mb-0">
            <img
              src={require("../img/Logo-wf-white.png")}
              alt="logo"
              className="object-cover"
            />
          </div>
          <div className="text-white flex flex-col gap-4 pr-6 text-center sm:text-left">
            <p className="text-2xl font-semibold">{t("footer.company")}</p>
            <p>{t("footer.addr1")}</p>
            <p>
              {t("footer.addr2")}
              <a href="mailto:operation@worldfair.co.th">
                operation@worldfair.co.th
              </a>
            </p>
          </div>
        </div>

        <div className="hidden md:flex gap-4 px-[80px] py-3 sm:py-[24px]">
          <div className="text-white flex flex-col gap-4 pr-6">
            <ul>
              {navItems.map(
                (i, x) =>
                  x < 4 && (
                    <li className="list-disc text-lg" key={x}>
                      <a href={i.href}>{i.title}</a>
                    </li>
                  )
              )}
            </ul>
          </div>
          <div className="text-white flex flex-col gap-4 pr-6">
            <ul>
              {navItems.map(
                (i, x) =>
                  x >= 4 && (
                    <li className="list-disc text-lg" key={x}>
                      <a href={i.href}>{i.title}</a>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
        <div className="block md:hidden py-[24px] w-fit mx-auto sm:w-full">
          <div className="flex flex-col justify-center gap-3">
            {socialItems.map((i, x) => (
              <div className="flex items-center gap-2">
                <img
                  src={require("../img/" + i.imgw)}
                  alt="logo"
                  className="w-[16px]"
                />
                <span className="text-white font-light">{i.title}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full bg-white min-h-[26px] h-fit hidden md:flex items-center justify-center">
        <div className="flex items-center gap-3">
          {socialItems.map((i, x) => (
            <div className="flex items-center gap-2">
              <img
                src={require("../img/" + i.img)}
                alt="logo"
                className="w-[16px]"
              />
              <span className="text-gray-500 font-light">{i.title}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
