import React, { useEffect } from "react";
import Hero from "./hero";
import Whyexhibit from "./whyexhibit";
import Profile from "./profile";
import Preparation from "./preparation";
import Services from "./services";

export default function Exhibitor(props) {
  useEffect(() => {
    document.title = "Home Mega Show - Exhibitor";
  }, []);
  return (
    <section id="exhibitor-page">
      <Hero />
      <Whyexhibit />
      <Profile />
      <Preparation />
      <Services />
    </section>
  );
}
