import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { FaCheck } from "react-icons/fa6";

export default function Preparation() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "exhibitor.preparation",
  });

  const prepareRef = useRef(null);

  const [prepareHeight, setPrepareHeight] = useState(0);

  useEffect(() => {
    setPrepareHeight(prepareRef.current.clientHeight);
  }, [prepareRef]);

  return (
    <section id="preparation" className="bg-gray-100">
      <div className="py-12 flex flex-col items-center">
        <h1 className="text-[#2C348B] text-center font-semibold text-2xl sm:text-3xl md:text-4xl">
          {t("title")}
        </h1>
        <div className="border-b mt-1 w-[320px] border-[#7E7E7E] mb-7"></div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div
          id="img-preparation"
          className="grid grid-cols-1 sm:grid-cols-2 gap-3 lg:grid-cols-1 place-items-center px-10 lg:px-20 xl:px-32 lg:h-[800px] overflow-hidden max-lg:mb-8"
        >
          <img
            src={require("./img/prepare/pp1.jpg")}
            alt="preparation1"
            className="w-full object-cover object-center"
          />
          <img
            src={require("./img/prepare/pp2.jpg")}
            alt="preparation1"
            className="w-full object-cover object-center"
          />
        </div>
        <div className="px-10 lg:px-20" ref={prepareRef}>
          <ul>
            <li className="mb-8">
              <span className="text-[#2C348B] font-semibold text-lg sm:text-xl md:text-2xl">
                <div className="flex gap-4 items-center">
                  <FaCheck />
                  {t("p1.title")}
                </div>
              </span>
              <span className="text-[#7E7E7E]">{t("p1.desc")}</span>
            </li>
            <li className="mb-8">
              <span className="text-[#2C348B] font-semibold text-lg sm:text-xl md:text-2xl">
                <div className="flex gap-4 items-center">
                  <FaCheck />
                  {t("p2.title")}
                </div>
              </span>
              <span className="text-[#7E7E7E]">
                {t("p2.desc")}{" "}
                <a
                  href="exhibitor#boothservices"
                  className="underline font-medium"
                >
                  {t("p2.contact")}
                </a>{" "}
                {t("p2.desc2")}
              </span>
            </li>
            <li className="mb-8">
              <span className="text-[#2C348B] font-semibold text-lg sm:text-xl md:text-2xl">
                <div className="flex gap-4 items-center">
                  <FaCheck />
                  {t("p3.title")}
                </div>
              </span>
              <span className="text-[#7E7E7E]">
                {t("p3.desc")}{" "}
                <a
                  href="exhibitor#electricalservices"
                  className="underline font-medium"
                >
                  {t("p3.contact")}
                </a>{" "}
                {t("p3.desc2")}
              </span>
            </li>
            <li className="mb-8">
              <span className="text-[#2C348B] font-semibold text-lg sm:text-xl md:text-2xl">
                <div className="flex gap-4 items-center">
                  <FaCheck />
                  {t("p4.title")}
                </div>
              </span>
              <span className="text-[#7E7E7E]">
                {t("p4.desc")}{" "}
                <a
                  href="exhibitor#otherservices"
                  className="underline font-medium"
                >
                  {t("p4.contact")}
                </a>{" "}
                {t("p4.desc2")}
              </span>
            </li>
            <li className="mb-8">
              <span className="text-[#2C348B] font-semibold text-lg sm:text-xl md:text-2xl">
                <div className="flex gap-4 items-center">
                  <FaCheck />
                  {t("p5.title")}
                </div>
              </span>
              <span className="text-[#7E7E7E]">
                {t("p5.desc")}{" "}
                <a
                  href="exhibitor#otherservices"
                  className="underline font-medium"
                >
                  {t("p5.contact")}
                </a>{" "}
                {t("p5.desc2")}
              </span>
            </li>
            <li className="mb-8">
              <span className="text-[#2C348B] font-semibold text-lg sm:text-xl md:text-2xl">
                <div className="flex gap-4 items-center">
                  <FaCheck />
                  {t("p6.title")}
                </div>
              </span>
              <span className="text-[#7E7E7E]">
                {t("p6.desc")}{" "}
                <a
                  href="exhibitor#organizerservices"
                  className="underline font-medium"
                >
                  {t("p6.contact")}
                </a>{" "}
                {t("p6.desc2")}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
