import React, { useEffect } from "react";
import Hero from "./Hero";
import About from "./about";
import Zone from "./zone";
import Support from "./support";
import Place from "./place";
import Testimonial from "./testimonial";
import { useParams } from "react-router-dom";

export default function Home(props) {
  useEffect(() => {
    document.title = "Home Mega Show - Home";
  }, []);

  const { cp } = useParams();

  useEffect(() => {
    if (cp !== undefined) {
      localStorage.setItem("campaign", cp);
    }
  }, [cp]);

  return (
    <section id="home-page">
      <Hero />
      <About />
      <Zone />
      <Support />
      <Place />
      <Testimonial />
    </section>
  );
}
